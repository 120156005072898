import './SearchBar.css';
import React, { useState } from 'react';

const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
]
const SearchBar = () => {
    const [selectedValue, setSelectedValue] = useState(''); // Valore selezionato
    const months = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre"
    ]

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        console.log('Valore selezionato:', event.target.value); // Azione al cambio
    };
    return(
    <div className="search-bar">
        {/*<div className="search-bar-select-container">*/}
        {/*  <label htmlFor="event-type" className="search-bar-select-label">Tipo di Evento</label>*/}
        {/*  <select id="event-type" className="custom-select">*/}
        {/*    <option>Concerto Jazz</option>*/}
        {/*    /!* Add other event types here *!/*/}
        {/*  </select>*/}
        {/*</div>*/}
        <div className="search-bar-select-container">
            <label htmlFor="location" className="search-bar-select-label">Luogo</label>
            <select id="location" className="custom-select">
                <option>Sassi di Matera</option>
                {/* Add other locations here */}
            </select>
        </div>
        <div className="search-bar-select-container">
            <label htmlFor="date" className="search-bar-select-label">Data</label>
            <select id="date" className="custom-select luglio-selected" value={selectedValue}
                    onChange={handleChange}>
                <option value="" disabled>
                    Seleziona un'opzione
                </option>
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    </div>
    );
}

export default SearchBar;
