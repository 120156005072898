const { ethers } = require("ethers");
const { MateraTokenABI, MateraTokenAddress } = require("./contractData");


export async function getTicketPrice(ticketId) {
    try {
        const provider = new ethers.providers.JsonRpcProvider("https://ethereum-sepolia-rpc.publicnode.com");

        let materatoken = new ethers.Contract(MateraTokenAddress, MateraTokenABI, provider);
        let ticketPrice = await materatoken.ticketPrice(ticketId);
        console.log("ticketPrice", ticketPrice);
        return ticketPrice;
    } 
    catch(err){
        console.log("err in getOwned: ", err);
    }

}
