import React from "react"
import Loader from "./Loader"

const PageLoader = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <Loader />
    </div>
  )
}

export default PageLoader
