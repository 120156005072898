import React from "react"
import { createRoot } from "react-dom/client"
import { ThemeProvider } from "styled-components"
import App from "./App"
import { theme } from "./styles/theme"
import "@fontsource/inter" // Defaults to weight 400
import { Auth0Provider } from "./components/Auth0Provider" // Corrected import path
import { Provider } from "react-redux"
import { store } from "./services/store"
const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <ThemeProvider theme={theme}>
    <Auth0Provider>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </ThemeProvider>
)
