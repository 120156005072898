const { ethers } = require("ethers");
const { MateraTokenABI, MateraTokenAddress } = require("./contractData");


export async function getOwned(owner, ticketId) {
    try {
        const provider = new ethers.providers.JsonRpcProvider("https://ethereum-sepolia-rpc.publicnode.com");

        let materatoken = new ethers.Contract(MateraTokenAddress, MateraTokenABI, provider);
        let totalOwned = await materatoken.balanceOf(owner, ticketId);
        console.log("totalOwned", Number(totalOwned));
        return totalOwned;
    } 
    catch(err){
        console.log("err in getOwned: ", err);
    }

}
