import React, { useRef } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom"
import HeroSection from "./components/HeroSection"
import EventList from "./components/EventList"
import TicketPurchase from "./components/TicketPurchase"
import WalletSection from "./components/WalletSection"
import EventsOfTheWeek from "./components/EventsOfTheWeek"
import BrandsSection from "./components/BrandsSection"
import BlogSection from "./components/BlogSection"
import FooterSection from "./components/FooterSection"
import MateraEvents from "./components/MateraEvents"
import UpcomingEvents from "./components/UpcomingEvents"
import CartPage from "./components/CartPage"
import Wishlist from "./components/Wishlist"
import SignIn from "./components/SignIn"
import SignOut from "./components/SignOut" // Ensure this import path is correct
import BlogDetails from "./components/BlogDetails"
import ScrollToTop from "./components/ScrollToTop"
import Profile from "./components/Profile" // Ensure this path matches the actual file
import { useAuth0 } from "./components/Auth0Provider" // Ensure this path is correct
import "./App.css"
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import WalletGenerator from "./components/WalletGenerator";

// 1. Get projectId
const projectId = 'b3cbcae9abe3d2c54321b341c57c26d9'

// 2. Set chains
const sepolia = {
  chainId: 11155111,
  name: 'Sepolia',
  currency: 'SepoliaETH',
  explorerUrl: 'https://sepolia.etherscan.io',
  // rpcUrl: 'https://cloudflare-eth.com'
  rpcUrl: 'https://sepolia.infura.io/v3/f0eeb58d51dd448ca78e42ca1525f559'
}
// const ethereum = {
//   chainId: 1,
//   name: 'Ethereum',
//   currency: 'ETH',
//   explorerUrl: 'https://etherscan.io',
//   rpcUrl: 'https://mainnet.infura.io/v3/f0eeb58d51dd448ca78e42ca1525f559'
// }

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 11155111 // used for the Coinbase SDK
})

// 5. Create a AppKit instance
createWeb3Modal({
  ethersConfig,
  chains: [sepolia],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

function App() {
  const eventListRef = useRef(null)
  const footerRef = useRef(null)
  const { isAuthenticated, loading } = useAuth0()

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection
                  eventListRef={eventListRef}
                  footerRef={footerRef}
                />
                <EventList eventListRef={eventListRef} />
                <TicketPurchase />
                <WalletSection />
                <EventsOfTheWeek />
                <BrandsSection />
                <BlogSection />
                <FooterSection ref={footerRef} />
              </>
            }
          />
          <Route
            path="/event/:token_id/:token_address"
            element={<MateraEvents />}
          />
          <Route path="/upcomingevents" element={<UpcomingEvents />} />
          <Route
            path="/cart"
            element={<CartPage eventListRef={eventListRef} />}
          />
          <Route
            path="/wishlist"
            element={<Wishlist footerRef={footerRef} />}
          />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/blog" element={<BlogDetails />} />
          <Route path="/createwallet" element={<WalletGenerator />} />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/signin" />}
          />
        </Routes>
      </div>
    </Router>
  )
}

export default App
