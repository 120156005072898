// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Profile.css */

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #242565;
}

.profile-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.profile-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.profile-name,
.profile-email {
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem;
  margin: 0.5rem 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color:#000000;
}

.profile-logout-button {
 background: #F5167E;
 font-family: 'Inter', sans-serif;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;EAEE,gCAAgC;EAChC,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAEA;CACC,mBAAmB;CACnB,gCAAgC;EAC/B,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["/* src/components/Profile.css */\n\n.profile-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  background-color: #242565;\n}\n\n.profile-card {\n  background-color: white;\n  padding: 2rem;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  max-width: 400px;\n  width: 100%;\n}\n\n.profile-title {\n  font-size: 2rem;\n  margin-bottom: 1rem;\n}\n\n.profile-image {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  object-fit: cover;\n  margin-bottom: 1rem;\n}\n\n.profile-name,\n.profile-email {\n  font-family: 'Inter', sans-serif;\n  font-size: 1.2rem;\n  margin: 0.5rem 0;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  color:#000000;\n}\n\n.profile-logout-button {\n background: #F5167E;\n font-family: 'Inter', sans-serif;\n  color: white;\n  border: none;\n  padding: 0.75rem 1.5rem;\n  border-radius: 50px;\n  cursor: pointer;\n  font-size: 1rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
