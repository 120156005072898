import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./EventsOfTheWeek.css"
// import LikeButton from "./LikeButton" // Import the LikeButton component
import { useGetEventsQuery } from "../services/endpoints/EventApi.api"
import moment from "moment"
// const events = [
//   {
//     id: 1,
//     image: "./eventi.jpg", // Ensure these paths are correct
//     title: "Matera Film Festival",
//     date: "14/06/2025",
//     day: "16",
//     month: "Dicemnbre",
//     description:
//       "Scopri un'esperienza unica con esibizioni artistiche, workshop e incontri con autori di fama internazionale."
//   },
//   {
//     id: 2,
//     image: "./image2.jpg", // Ensure these paths are correct
//     title: "Concerto sotto le stelle",
//     date: "14/06/2025",
//     day: "16",
//     month: "Ottobre",
//     description:
//       "Vivi una serata magica con un concerto all'aperto nelle suggestive cornici dei Sassi."
//   },
//   {
//     id: 3,
//     image: "./image3.jpg", // Ensure these paths are correct
//     title: "Mostra d'Arte Contemporanea",
//     date: "14/06/2025",
//     day: "16",
//     month: "Marzo",
//     description:
//       "Esplora le opere di artisti emergenti e affermati in una mostra innovativa."
//   }
// ]

const EventsOfTheWeek = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const {
    data: eventsResp,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useGetEventsQuery({
    skip: 0,
    limit: 3,
    // isTopWeekEvent: false,
    isadmin: false
  })

  console.log(eventsResp)
  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? eventsResp.length - 1 : prevIndex - 1
    )
  }

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === eventsResp.length - 1 ? 0 : prevIndex + 1
    )
  }

  return (
    <section className="events-week-wrapper">
      <div className="events-week-title">Prossimi Eventi</div>
      <div className="events-week-carousel">
        <button
          className="carousel-week-button left"
          onClick={handleLeftClick}
          aria-label="Previous Event">
          &lt;
        </button>

        <div
          className="events-week-list"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {eventsResp?.events?.map((event, index) => (
            // <Link
            //   to={`/event/${event?.token_id}/${event?.token_address}`}
            //   key={index}
            //   className="event-week-card-link">
            //   <div className="event-week-card">
            //     <img
            //       src={event?.image}
            //       alt={event?.name}
            //       className="event-week-image"
            //     />
            //     <div className="event-week-card-content">
            //       <div className="event-week-card-details-wrapper">
            //         <div className="event-week-card-date">
            //           <span className="event-week-card-month">
            //             {/*{moment(event.date.day).format("MMM")}*/}
            //             {event.month}
            //           </span>
            //           <span className="event-week-card-day">
            //             {/*{moment(event.date).format("DD")}*/}
            //             {event.day}
            //           </span>
            //         </div>
            //         <div className="event-week-card-details">
            //           <h3 className="event-week-card-title">
            //             {event?.metadata?.name}
            //           </h3>
            //           <p className="event-week-card-description">
            //             {event?.metadata?.description}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //     <div className="event-week-card-icons">
            //       <LikeButton />
            //     </div>
            //   </div>
            // </Link>
              <Link
                  key={event.id}
                  to={`/event/${event?.token_id}/${event?.token_address}`}
                  className="event-cardUpcomingEvents">
                <img src={event?.metadata?.image} alt={event?.metadata?.name} />
                <div className="event-card-contentUpcomingEvents">
                  <div className="event-card-dateUpcomingEvents">
                  <span className="month">
                    {moment(event.dateTime).format("MMM")}
                  </span>
                    <span className="day">
                    {moment(event.dateTime).format("DD")}
                  </span>
                  </div>
                  <div className="event-card-textUpcomingEvents">
                    <div className="event-card-titleUpcomingEvents">
                      {event?.metadata?.name}
                    </div>
                    <div className="event-card-descriptionUpcomingEvents">
                      {event?.metadata?.description}
                    </div>
                  </div>
                </div>
              </Link>
          ))}
        </div>
        <button
          className="carousel-week-button right"
          onClick={handleRightClick}
          aria-label="Next Event">
          &gt;
        </button>
      </div>
      <div className="carousel-week-indicators">
        {eventsResp?.events?.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
            role="button"
            tabIndex="0"
            aria-label={`Go to event ${index + 1}`}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCurrentIndex(index)
              }
            }}></span>
        ))}
      </div>
    </section>
  )
}

export default EventsOfTheWeek
