import React from "react"
import styled from "styled-components"

const LoaderWrapper = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top: 3px solid #3d37f1;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = () => <LoaderWrapper />

export default Loader
