// src/components/TicketPurchase.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './TicketPurchase.css';
import icon1 from '../assets/icons/Add to Favorites.png'; // Update the path according to your project structure
import icon2 from '../assets/icons/Wallet.png';
import icon3 from '../assets/icons/NFT.png';
import {useWeb3Modal, useWeb3ModalAccount} from "@web3modal/ethers5/react";
import {useAuth0} from "./Auth0Provider";

const TicketPurchase = () => {
  const navigate = useNavigate(); // Get the navigate function
  const { open } = useWeb3Modal();
  const {isConnected, address} = useWeb3ModalAccount();
  const { isAuthenticated } = useAuth0() // Get authentication state from Auth0
  async function handleConnect() {
    try {
      open()
    } catch (err) {
      console.log(err)
    }
  }
  // Function to handle the click event
  const handleCreateWalletClick = () => {
    navigate('/createwallet'); // Navigate to the sign-in page
  };

  return (
    <section className="purchase-section">
      <h2 className="purchase-title">Acquista il tuo biglietto in 3 click</h2>
      <div className="purchase-cards">
        <div className="purchase-card">
          <img src={icon1} alt="Icon 1" className="icon" />
          <h3>Scegli l’evento a cui partecipare</h3>
          <p>
            Immergiti nell’atmosfera di Matera con eventi imperdibili: musica dal vivo, teatro d’autore e iniziative culturali che celebrano la bellezza della città.
          </p>
        </div>
        <div className="purchase-card">
          <img src={icon2} alt="Icon 2" className="icon" />
          <h3>Crea o connetti il tuo wallet</h3>
          <p>
            Accedi al mondo degli NFT in pochi passi: crea il tuo wallet personale o connettine uno esistente per iniziare a gestire i tuoi asset digitali in totale sicurezza.
          </p>
        </div>
        <div className="purchase-card">
          <img src={icon3} alt="Icon 3" className="icon" />
          <h3>Ottieni il tuo ticket NFT</h3>
          <p>
            Assicurati un accesso esclusivo agli eventi: acquista il tuo ticket NFT e vivi un’esperienza unica con la sicurezza e l’innovazione della blockchain.
          </p>
        </div>
      </div>
      <div className="purchase-buttons">
        {!isConnected ? (
            <>
            <button className="purchase-button primary" onClick={handleConnect}>Connetti Wallet</button>
            <button className="purchase-button" onClick={handleCreateWalletClick}>Crea Wallet</button></> ) :
            null}
      </div>
    </section>
  );
};

export default TicketPurchase;
