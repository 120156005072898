import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapComponent = ({lat = 40.666379,long = 16.604320}) => {
    const mapContainerStyle = {
        width: "100%",
        height: "400px",
    };

    const center = {
        lat: lat, // Latitudine
        lng: long, // Longitudine
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyBsfJvZtHBzrJsIqXB28oetb9-MIzXlmk8">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
            >
                {/* Aggiungi un marker */}
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;
