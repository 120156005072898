// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brands-section {
  padding: 50px 20px;
  text-align: center;
  background-color: #fff;
}

.brands-section h2 {
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  color: #242565;
}

.brands-section p {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #6A6A6A;
  margin-bottom: 30px;
}

.brands-list {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.brand-logo {
  height: 40px;
  margin: 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/BrandsSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".brands-section {\n  padding: 50px 20px;\n  text-align: center;\n  background-color: #fff;\n}\n\n.brands-section h2 {\n  font-family: 'Inter', sans-serif;\n  font-size: 2rem;\n  color: #242565;\n}\n\n.brands-section p {\n  text-align: center;\n  font-family: 'Inter', sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  color: #6A6A6A;\n  margin-bottom: 30px;\n}\n\n.brands-list {\n  display: flex;\n  justify-content: center;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n\n.brand-logo {\n  height: 40px;\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
