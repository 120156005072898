// EventList.js
import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import EventCard from "./EventCard"
import "./EventList.css"
import { useGetEventsQuery } from "../services/endpoints/EventApi.api"
import moment from "moment"
import { useCategoriesQuery } from "../services/endpoints/category.api"
import Loader from "./Loader.js"
const events = [
  {
    image: "./image1.jpg",
    title: "Matera Film Festival",
    date: "June 14",
    location: "Sassi di Matera",
    description:
      "Scopri un'esperienza unica con esibizioni artistiche, workshop e incontri con autori di fama internazionale.",
    width: "358px",
    height: "280px",
    price: "0.0001 ETH"
  },
  {
    image: "./image2.jpg",
    title: "Concerto sotto le stelle",
    date: "June 20",
    location: "Sassi di Matera",
    description:
      "Vivi una serata magica con un concerto all'aperto nelle suggestive cornici dei Sassi.",
    width: "358px",
    height: "280px",
    price: "0.0001 ETH"
  },
  {
    image: "./image3.jpg",
    title: "Mostra d'Arte Contemporanea",
    date: "June 18",
    location: "Sassi di Matera",
    description:
      "Esplora le opere di artisti emergenti e affermati in una mostra innovativa.",
    width: "358px",
    height: "280px",
    price: "0.0003 ETH"
  },
  {
    image: "./image1.jpg",
    title: "Matera Film Festival",
    date: "June 14",
    location: "Sassi di Matera",
    description:
      "Scopri un'esperienza unica con esibizioni artistiche, workshop e incontri con autori di fama internazionale.",
    width: "358px",
    height: "280px",
    price: "0.0001 ETH"
  },
  {
    image: "./image2.jpg",
    title: "Concerto sotto le stelle",
    date: "June 20",
    location: "Sassi di Matera",
    description:
      "Vivi una serata magica con un concerto all'aperto nelle suggestive cornici dei Sassi.",
    width: "358px",
    height: "280px",
    price: "0.0001 ETH"
  },
  {
    image: "./image3.jpg",
    title: "Mostra d'Arte Contemporanea",
    date: "June 18",
    location: "Sassi di Matera",
    description:
      "Esplora le opere di artisti emergenti e affermati in una mostra innovativa.",
    width: "358px",
    height: "280px",
    price: "0.0003 ETH"
  },
  {
    image: "./image1.jpg",
    title: "Matera Film Festival",
    date: "June 14",
    location: "Sassi di Matera",
    description:
      "Scopri un'esperienza unica con esibizioni artistiche, workshop e incontri con autori di fama internazionale.",
    width: "358px",
    height: "280px",
    price: "0.0001 ETH"
  },
  {
    image: "./image2.jpg",
    title: "Concerto sotto le stelle",
    date: "June 20",
    location: "Sassi di Matera",
    description:
      "Vivi una serata magica con un concerto all'aperto nelle suggestive cornici dei Sassi.",
    width: "358px",
    height: "280px",
    price: "0.0001 ETH"
  },
  {
    image: "./image3.jpg",
    title: "Mostra d'Arte Contemporanea",
    date: "June 18",
    location: "Sassi di Matera",
    description:
      "Esplora le opere di artisti emergenti e affermati in una mostra innovativa.",
    width: "358px",
    height: "280px",
    price: "0.0003 ETH"
  }
]

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre"
]

const categories = ["Concert", "Sports", "Theater", "Family"]

const EventList = ({ eventListRef }) => {
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [showHiddenEvents, setShowHiddenEvents] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const navigate = useNavigate()
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useGetEventsQuery({
    skip: 0,
    limit: showHiddenEvents ? 100 : 20,
    isadmin: false,
    ...(selectedCategory && { category: selectedCategory })
  })
  const {
    data: categoriesResp,
    isLoading: isLoadingCategories,
    isFetching: isFetchingCategories
  } = useCategoriesQuery()
  const categoriesOption = useMemo(() => {
    return categoriesResp?.categories?.map((category) => ({
      label: category.name,
      value: category._id
    }))
  }, [categoriesResp])
  console.log("selectedFilter", { selectedFilter, filterOptions })

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter)
    setShowDropdown(true)
    if (filter === "Data") {
      setFilterOptions(months)
    } else if (filter === "Categoria") {
      setFilterOptions(categories)
    } else {
      setFilterOptions([])
    }
  }

  const handleToggleHiddenEvents = () => {
    setShowHiddenEvents(!showHiddenEvents)
  }

  const handleEventClick = (event) => {
    navigate(`/event/${event?.token_id}/${event?.token_address}`, {
      state: { event }
    })
  }

  const handleHeartClick = (e) => {
    e.stopPropagation() // Stop event propagation
    navigate("/profile") // Navigate to the Wishlist page
  }

  const handleOptionSelect = (option) => {
    console.log(`Selected ${option}`)
    if (selectedFilter === "Categoria") setSelectedCategory(option)
    else {
      setSelectedCategory()
    }
    setShowDropdown(false)
    setShowHiddenEvents(false)
    // Implement filter logic based on selected option here
  }

  const isLoading =
    isLoadingEvents ||
    isFetchingEvents ||
    isLoadingCategories ||
    isFetchingCategories
  return (
    <div ref={eventListRef} className="event-section">
      <div className="section-header">
        <h2 className="section-title">Eventi</h2>
        <div className="filter-buttons">
          {/*<FilterButton*/}
          {/*  filter="Data"*/}
          {/*  selectedFilter={selectedFilter}*/}
          {/*  handleFilterClick={handleFilterClick}*/}
          {/*  showDropdown={showDropdown && selectedFilter === "Data"}*/}
          {/*  filterOptions={filterOptions}*/}
          {/*  handleOptionSelect={handleOptionSelect}*/}
          {/*/>*/}
          {/*<FilterButton*/}
          {/*  filter="Tipologia"*/}
          {/*  selectedFilter={selectedFilter}*/}
          {/*  handleFilterClick={handleFilterClick}*/}
          {/*  showDropdown={showDropdown && selectedFilter === "Tipologia"}*/}
          {/*  filterOptions={filterOptions}*/}
          {/*  handleOptionSelect={handleOptionSelect}*/}
          {/*/>*/}
          <FilterButton
            filter="Categoria"
            selectedFilter={selectedFilter}
            handleFilterClick={handleFilterClick}
            showDropdown={showDropdown && selectedFilter === "Categoria"}
            filterOptions={categoriesOption}
            handleOptionSelect={handleOptionSelect}
          />
        </div>
      </div>
      {isLoading && !showHiddenEvents ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",

            justifyContent: "center"
          }}>
          <Loader />
        </div>
      ) : (
        <div className="event-list">
          {events?.events?.map((event, index) => (
            <div
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleEventClick(event)}>
              <EventCard
                image={event?.metadata?.image}
                title={event?.metadata?.name}
                date={moment(event.dateTime).format("MMM DD")}
                description={event?.metadata?.description}
                // location={event.location}
                width={"358px"}
                height={"280px"}
                onHeartClick={handleHeartClick}
              />
            </div>
          ))}
        </div>
      )}
      {events?.events?.length > 3 ? (<>
      <button className="load-more-button" onClick={handleToggleHiddenEvents}>
        {showHiddenEvents ? "Nascondi" : "Scopri tutti"}
      </button></>): events?.events?.length ===0 ? 'Nessun evento disponibile' : '' }
    </div>
  )
}

const FilterButton = ({
  filter,
  selectedFilter,
  handleFilterClick,
  showDropdown,
  filterOptions,
  handleOptionSelect
}) => (
  <div className="filter-button-wrapper">
    <button
      className={`filter-button ${selectedFilter === filter ? "active" : ""}`}
      onClick={() => handleFilterClick(filter)}>
      {filter}
      <SvgArrow />
    </button>
    {showDropdown && (
      <div className="filter-dropdown">
        {filterOptions.map((option, index) => (
          <div
            key={index}
            className="filter-option"
            onClick={() => handleOptionSelect(option?.value ?? option)}>
            {option?.label ?? option}
          </div>
        ))}
      </div>
    )}
  </div>
)

const SvgArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    aria-hidden="true">
    <path
      d="M1.3396 1L6.3396 6L11.3396 1"
      stroke="#1D275F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EventList
