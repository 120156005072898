import React, { useState } from "react";
import logo from "../assets/icons/Frame 55.png";
import logoPdf from "../assets/icons/logo_wallet.png";
import './SignIn.css';
import {useNavigate} from "react-router-dom";
import jsPDF from "jspdf";

function WalletPage() {
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showFirstDiv, setShowFirstDiv] = useState(true);

    const navigate = useNavigate() // Hook to programmatically navigate

    // Funzione per richiamare l'API
    const fetchWallet = async () => {
        setLoading(true);
        try {
            const response = await fetch("http://localhost:8080/api/create-wallet", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch wallet");
            }
            const data = await response.json();
            setWallet(data);
            setShowFirstDiv(!showFirstDiv);
        } catch (error) {
            console.error("Error fetching wallet:", error);
            alert("Errore nella generazione del wallet");
        } finally {
            setLoading(false);
        }
    };
    // Funzione per generare il PDF
    const generatePDF = (wallet) => {
        const pdf = new jsPDF({
            orientation: "landscape", // Imposta orientamento orizzontale
            unit: "mm",
            format: [210, 148], // Metà A4: 148mm x 210mm
        });
        pdf.setFillColor(36, 37, 101);
        pdf.rect(0, 0, 210, 148, "F");
        // Aggiungi immagine
        const imgX = 65; // X pos dell'immagine
        const imgY = 20; // Y pos dell'immagine
        const imgWidth = 80; // Larghezza immagine
        const imgHeight = 18; // Altezza immagine
        pdf.addImage(logoPdf, "PNG", imgX, imgY, imgWidth, imgHeight);

        // Aggiungi testo con stile bianco
        pdf.setTextColor(255, 255, 255);
        pdf.setFont("helvetica", "bold");

        const marginLeft = 20;
        const startY = 60;

        pdf.setFontSize(12);
        pdf.text("Address:", marginLeft, startY);
        pdf.setFont("helvetica", "normal");
        pdf.text(wallet.address, marginLeft, startY + 8);

        pdf.setFont("helvetica", "bold");
        pdf.text("Private Key:", marginLeft, startY + 16);
        pdf.setFont("helvetica", "normal");
        pdf.text(wallet.privateKey, marginLeft, startY + 24);

        pdf.setFont("helvetica", "bold");
        pdf.text("Mnemonic:", marginLeft, startY + 32);
        pdf.setFont("helvetica", "normal");
        pdf.text(wallet.mnemonic, marginLeft, startY + 40);

        // Salva il PDF
        pdf.save(`wallet.pdf`);
    };

    // Funzione per copiare il testo negli appunti
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        alert("Copied to clipboard!");
    };

    const goToHomePage = () => {
        navigate('/');
    };
    return (
        <div className="sign-in-container" >
            <div className="sign-in-card" style={{width: '80%', maxWidth: '100%'}}>
                <div className="sign-in-header">
                    <div className="sign-in-logo-container">
                        <img src={logo} alt="EventiNFT Logo" className="sign-in-logo-icon" />
                        <span className="sign-in-logo-text">Crea Wallet</span>
                    </div>
                </div>
                {showFirstDiv && (
                <div className="sign-in-form" id="genera" style={{maxWidth: '100%', height:'100%', margin: '0', padding: '15px'}}>
                    <button  onClick={fetchWallet} className="sign-in-btn-primary" disabled={loading} style={{width: '50%'}}>
                        {loading ? "Loading..." : "Genera il tuo wallet"}
                    </button>
                </div> )}
                {wallet && (
                <div className="subscribe-form" style={{width: '100%', height:'100%', margin: '0', padding: '15px'}}>
                    <div style={{ marginTop: "20px", width: '100%', height: '100%'}}>
                        <div style={{ padding:'35px', marginBottom:'30px'}}>
                            <span >
                                Wallet generato con successo! </span>
                            <span>
                                Ti ricordiamo che le chiavi private sono essenziali per accedere al tuo wallet e ai
                                tuoi fondi. NON condividere mai le tue chiavi private e custodiscile in un luogo sicuro.
                            </span>
                        </div>
                        <div style={{ marginBottom: "10px" , width: "100%" }}>
                            <label>Address:</label>
                            <div>
                                <input
                                    type="text"
                                    value={wallet.address}
                                    readOnly
                                    style={{ width: "100%", padding: "10px" }}
                                />
                                <button
                                    onClick={() => copyToClipboard(wallet.address)}
                                >
                                    Copia
                                </button>
                            </div>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                            <label>Private Key:</label>
                            <div>
                                <input
                                    type="text"
                                    value={wallet.privateKey}
                                    readOnly
                                    style={{ width: "100%", padding: "10px" }}
                                />
                                <button
                                    onClick={() => copyToClipboard(wallet.privateKey)}
                                >
                                    Copia
                                </button>
                            </div>
                        </div>

                        <div style={{ marginBottom: "10px" }}>
                            <label>Mnemonic:</label>
                            <div>
              <input
                  value={wallet.mnemonic}
                  readOnly
                  rows={3}
                  style={{ width: "100%", padding: "10px" }}
              />
                                <button
                                    onClick={() => copyToClipboard(wallet.mnemonic)}
                                >
                                    Copia
                                </button>
                            </div>

                        </div>
                        <div style={{ marginBottom: "10px" , width: "100%", marginTop: "50px"}}>
                            <div>
                                <button style={{ marginLeft: "0", float: "left"}}
                                        onClick={() => goToHomePage()}
                                >
                                    Torna all'Homepage
                                </button>
                                <button style={{ marginLeft: "0", float: "right"}}
                                        onClick={() => generatePDF(wallet)}
                                >
                                    Genera PDF
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                )}
            </div>
        </div>
    );
}

export default WalletPage;
